import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ProductUtilsService = /** @class */ (function () {
    function ProductUtilsService(router) {
        this.router = router;
    }
    ProductUtilsService.prototype.getBookingDuration = function (product) {
        var duration = null;
        if (product.scheduleData && product.scheduleData.duration) {
            var months = this.getDurationKeyString('month', product.scheduleData.duration.months);
            var days = this.getDurationKeyString('day', product.scheduleData.duration.days);
            var hours = this.getDurationKeyString('hr', product.scheduleData.duration.hours);
            var minutes = this.getDurationKeyString('min', product.scheduleData.duration.minutes);
            duration = months + " " + days + " " + hours + " " + minutes;
        }
        return duration;
    };
    ProductUtilsService.prototype.getDurationKeyString = function (key, value) {
        return value ? value > 1 ? value + " " + key + "s" : value + " " + key : '';
    };
    ProductUtilsService.prototype.goToProduct = function (product) {
        if (product.ptype === 'child') {
            this.router.navigate(["product/" + product.parentSlug.name + "/" + product.slug.name]);
        }
        else {
            this.router.navigate(["product/" + product.slug.name]);
        }
    };
    ProductUtilsService.prototype.goToProductUsingRouterLink = function (product) {
        // console.log('product', product);
        return product.ptype === 'child' ?
            ['/product', product.parentSlug.name, product.slug.name] :
            ['/product', product.slug.name];
    };
    ProductUtilsService.prototype.getProductQueryParams = function (data) {
        if (data && data.categorySlug && data.subCatSlug) {
            return { categorySlug: data.categorySlug, subCatSlug: data.subCatSlug };
        }
        else if (data && data.categorySlug && !data.subCatSlug) {
            return { categorySlug: data.categorySlug };
        }
        else if (data && data.brandSlug) {
            return { brandSlug: data.brandSlug };
        }
        else if (data && data.vendorSlug) {
            return { vendorSlug: data.vendorSlug };
        }
    };
    ProductUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductUtilsService_Factory() { return new ProductUtilsService(i0.ɵɵinject(i1.Router)); }, token: ProductUtilsService, providedIn: "root" });
    return ProductUtilsService;
}());
export { ProductUtilsService };
