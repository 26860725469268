import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, ActionSheetController, IonContent, NavController, ModalController, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { UserService } from 'src/app/services/user/user.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { SelectAddressPage } from '../select-address/select-address.page';
import { AutoConfirmPaymentPage } from '../auto-confirm-payment/auto-confirm-payment.page';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { CouponsListPage } from '../pages/coupons-list/coupons-list.page';
import { MultiRegionService } from '../services/multi-region/multi-region.service';
import { encodeURL } from '../services/parseURL';
import { CartService } from '../services/cart/cart.service';
import { OrderService } from '../services/order/order.service';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { DeliverySettingsService } from '../services/delivery-settings/delivery-settings.service';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
var DeliveryScheduleModalPage = /** @class */ (function () {
    function DeliveryScheduleModalPage(events, router, activatedRoute, alertController, loadingController, storage, actionSheetController, modalController, navCtrl, labelService, configService, userService, sharedService, toastController, regionService, cartService, orderService, adminSettingsService, deliverySettingsService, multiCountriesService) {
        this.events = events;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.actionSheetController = actionSheetController;
        this.modalController = modalController;
        this.navCtrl = navCtrl;
        this.labelService = labelService;
        this.configService = configService;
        this.userService = userService;
        this.sharedService = sharedService;
        this.toastController = toastController;
        this.regionService = regionService;
        this.cartService = cartService;
        this.orderService = orderService;
        this.adminSettingsService = adminSettingsService;
        this.deliverySettingsService = deliverySettingsService;
        this.multiCountriesService = multiCountriesService;
        this.products = [];
        this.showCommentBoxAndImage = false;
        this.listOfCommentImages = {};
        this.placeholderMsgInCommentBox = '';
        this.defaultDeliveryAmt = 0;
        this.freeDeliveryAmt = 0;
        this.minOrderAmount = 0;
        this.productsPrice = 0;
        this.showLoader = true;
        this.couponCode = '';
        this.couponDiscount = 0;
        this.defaultGst = 0;
        this.couponApplied = false;
        this.couponId = '';
        this.timeSchedules = [];
        this.scheduledDates = [];
        this.gstAmount = 0;
        this.showPriceInfoLoader = true;
        this.totalMrp = 0;
        this.discountOnMrp = 0;
        this.deliveryGstObj = {};
        this.paymentDetails = {};
        this.addressType = 'shipping';
        this.accountType = 'personal';
        this.customerGstNo = '';
        this.storeAddress = {};
        this.isStorePickup = false;
        this.storePickupCharges = 0;
        this.deliveryType = 'delivery';
        this.isGstApplicable = true;
        this.isDeliveryScheduleMandatory = false;
        this.minHrsReq = 0;
        this.allowSameDayDelivery = false;
        this.minPincodeAmnt = 0;
        this.SHARED_LABELS = {};
        this.ORDER_SUMMARY_LABELS = {};
        this.offerMsg = '';
        this.needToDisable = false;
        this.isNoSubscriptionOrder = false;
        this.manageSlots = [];
        this.membershipDiscount = 0;
        this.isOnlyMembership = false;
        this.uploadDocSettings = {
            isActive: false
        };
        this.uploadedDoc = {
            name: '',
            uploads: []
        };
        this.orderUploadedDocImgsLimit = 5;
        this.storeDelivery = {
            estimatedTime: '',
            isActive: true
        };
        this.isKmDeliveryExist = false;
        this.isWeightDeliveryExist = false;
        this.estimatedDeliveryTime = '';
        this.instantDelivery = {
            isActive: false,
            time: null,
            isAvailable: false
        };
        this.isCodAvailableForCoupon = true;
        this.regionNotDeliverable = false;
        this.issueInGettingLocation = false;
        this.multiRegion = false;
        this.regionType = 'pincodes';
        this.freeProductsLimit = [];
        this.freeProductsAdded = [];
        this.customDeliveryOptions = [];
        this.extraChargeOnOrder = {
            name: '',
            charge: 0
        };
        this.customField = {
            active: false,
            name: '',
            value: ''
        };
        this.totalAddonsPrice = 0;
        var cashfreeOrderId = this.activatedRoute.snapshot.queryParamMap.get('cf_id');
        if (cashfreeOrderId && cashfreeOrderId.length > 0) {
            this.orderService.getOrderDetailsCashfree(cashfreeOrderId);
        }
    }
    DeliveryScheduleModalPage.prototype.ngOnInit = function () {
    };
    DeliveryScheduleModalPage.prototype.getStickyHeight = function (height) {
        this.stickyHeight = height;
        console.log('sticky height', this.stickyHeight);
    };
    DeliveryScheduleModalPage.prototype.getScrollPos = function (pos) {
        if (pos > this.stickyHeight) {
            this.stickyHeader = true;
        }
        else {
            this.stickyHeader = false;
        }
    };
    DeliveryScheduleModalPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, billingAddress, storeInfo, _c, _d;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.initializeSubscriptions();
                        this.varInit();
                        _a = this;
                        return [4 /*yield*/, this.storage.get('productsInCart')];
                    case 1:
                        _a.products = _e.sent();
                        this.products.forEach(function (product) {
                            if (!product.hasOwnProperty('orderType') || (product.hasOwnProperty('orderType') && product.orderType !== 'subscription')) {
                                _this.isNoSubscriptionOrder = true;
                            }
                            if ('extraCharges' in product && product.extraCharges.charge > 0) {
                                var charge = product.extraCharges.chargeAllQty ? product.extraCharges.charge : product.extraCharges.charge / product.quantity;
                                product.price += charge;
                                if ('mrpPrice' in product) {
                                    product.mrpPrice += charge;
                                }
                                product.extraCharges.charge = charge * product.quantity;
                            }
                            _this.totalAddonsPrice += ('addOns' in product ? product.addOns.totalPrice : 0);
                        });
                        _b = this;
                        return [4 /*yield*/, this.storage.get('userDefaultAddress')];
                    case 2:
                        _b.address = _e.sent();
                        return [4 /*yield*/, this.storage.get('userBillingAddress')];
                    case 3:
                        billingAddress = _e.sent();
                        this.billingAddress = billingAddress || this.address;
                        if (this.address && this.billingAddress) {
                            if (this.isSame(this.address, this.billingAddress)) {
                                this.isShippingAndBillingSame = true;
                            }
                            else {
                                this.isShippingAndBillingSame = false;
                            }
                        }
                        return [4 /*yield*/, this.storage.get('storeInfo')];
                    case 4:
                        storeInfo = _e.sent();
                        if (storeInfo && storeInfo !== undefined) {
                            this.showCommentBoxAndImage = storeInfo.allowComment || false;
                            this.placeholderMsgInCommentBox = storeInfo.commentMsg;
                            this.storeAddress = storeInfo.storeAddress || {};
                            this.offerMsg = storeInfo.offerMsg || '';
                            this.uploadDocSettings = storeInfo.allowImageUpload || this.uploadDocSettings;
                            if (this.uploadDocSettings.isActive) {
                                this.uploadedDoc['name'] = this.uploadDocSettings.name;
                            }
                            this.extraChargeOnOrder.name = 'extraCharge' in storeInfo ? storeInfo.extraCharge.chargeName : '';
                            this.customField = 'custom' in storeInfo && storeInfo.custom.active ? storeInfo.custom : { active: false };
                        }
                        _c = this;
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 5:
                        _c.userId = _e.sent();
                        if (!this.userId) return [3 /*break*/, 7];
                        _d = this;
                        return [4 /*yield*/, this.userService.getUserDetails('return')];
                    case 6:
                        _d.user = _e.sent();
                        this.customerGstNo = this.user.customerGstNo || '';
                        _e.label = 7;
                    case 7:
                        this.events.publish('delivery-settings:getDeliverySettingsData');
                        this.events.publish('delivery-settings:getManageSlots');
                        this.multiRegion = this.configService.environment.multiRegion;
                        if (this.multiRegion) {
                            this.events.publish('multi-region:getActiveStatus');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.urlPdtName = function (pname) {
        return encodeURL(pname);
    };
    DeliveryScheduleModalPage.prototype.varInit = function () {
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.orderUploadedDocImgsLimit = this.configService.environment.orderUploadedDocImgsLimit;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.ORDER_SUMMARY_LABELS = this.labelService.labels['ORDER_SUMMARY'];
    };
    DeliveryScheduleModalPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    DeliveryScheduleModalPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('order:orderSuccessfullyPlaced', function () {
            if (_this.sharedService.loading) {
                _this.sharedService.loading.dismiss();
            }
            _this.orderPlacedAlert();
        });
        this.events.subscribe('order:autoConfirmPlaceOrderSuccess', function (orderObj) {
            console.log("Came in delivery schedule modal");
            _this.presentAutoConfirmPaymentPageModal(orderObj);
        });
        this.events.subscribe('delivery-settings:publishDeliverySettingsData', function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var customDeliveryOptions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deliverySettingsService.getCustomDelivery()];
                    case 1:
                        customDeliveryOptions = _a.sent();
                        customDeliveryOptions.unshift(tslib_1.__assign({ id: 'standard' }, data));
                        this.customDeliveryOptions = [];
                        customDeliveryOptions.forEach(function (option) {
                            if (_this.isCustomDeliveryAvailable(option)) {
                                _this.customDeliveryOptions.push(option);
                            }
                        });
                        if (this.customDeliveryOptions.length) {
                            this.selectedCustomDeliveryType = this.customDeliveryOptions[0].id;
                            this.setDeliveryObj(this.customDeliveryOptions[0]);
                        }
                        else {
                            this.setDeliveryObj(data);
                        }
                        this.events.publish('admin-settings:getPaymentInfoData');
                        return [2 /*return*/];
                }
            });
        }); });
        this.events.subscribe('admin-settings:publishPaymentInfoData', function (data) {
            if (!_this.isEmptyObj(data)) {
                if (data.minOrderAmount !== '') {
                    _this.minOrderAmount = parseInt(data.minOrderAmount);
                }
                if (data.maxOrderAmount !== '') {
                    _this.maxOrderAmount = parseInt(data.maxOrderAmount);
                }
                _this.autoConfirmOrder = data.autoConfirmOrder;
                _this.isGstApplicable = typeof data.isGstApplicable !== undefined ? data.isGstApplicable : true;
                if (_this.checkIsInternationalUser()) {
                    _this.isGstApplicable = false;
                }
                var additionalInfo = {
                    totalAddonsPrice: _this.totalAddonsPrice
                };
                _this.events.publish('delivery-settings:getOrderPaymentDetails', _this.address, _this.products, _this.isGstApplicable, _this.customDeliveryOptions[0] || null, additionalInfo);
                _this.showLoader = false;
                _this.showPriceInfoLoader = true;
            }
        });
        this.events.subscribe('delivery-settings:publishOrderPaymentDetails', function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.paymentDetails = response;
                        if (!this.isKmDeliveryExist && !this.isWeightDeliveryExist) {
                            this.getExtraDeliveryFields();
                        }
                        this.setDefaultSelectionofDelivery();
                        this.initPaymentInfo();
                        return [4 /*yield*/, this.getFreeProductLimits()];
                    case 1:
                        _a.sent();
                        this.checkFreeProductStatus();
                        this.showPriceInfoLoader = false;
                        if (this.loader) {
                            this.loader.dismiss();
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        this.events.subscribe('coupon-codes:couponCodeNotApplied', function (msg) {
            _this.couponCode = '';
            _this.loader.dismiss();
            _this.presentAlert(msg);
        });
        this.events.subscribe('coupon-codes:couponCodeApplied', function (response) {
            if (response.details.totalCouponDiscount === 0) {
                _this.loader.dismiss();
                _this.presentAlert("" + _this.ORDER_SUMMARY_LABELS['coupon_not_applicable']);
            }
            else {
                var coupon = response.data;
                _this.couponApplied = true;
                _this.couponId = coupon.couponId;
                _this.isCodAvailableForCoupon = 'codAvailable' in coupon ? coupon.codAvailable : true;
                _this.gstAmount = response.details.totalGst;
                _this.products = response.details.products;
                _this.totalAmountToPaid = response.details.totalAmountToPaid;
                _this.couponDiscount = response.details.totalCouponDiscount;
                _this.totalMrp = 'totalMrp' in response.details ? response.details.totalMrp : _this.totalMrp;
                _this.discountOnMrp = 'discountOnMrp' in response.details ? response.details.discountOnMrp : _this.discountOnMrp;
                var price_1 = 0;
                var totalGst_1 = 0;
                _this.products.map(function (p) {
                    price_1 += p.price * p.quantity;
                    if (p.gstObj) {
                        totalGst_1 += p.gstObj.total;
                    }
                });
                if (price_1 >= _this.freeDeliveryAmt) {
                    _this.deliveryFree();
                }
                if (_this.deliveryType === 'pickup' && _this.isStorePickup && _this.storeAddress.hasOwnProperty('address')) {
                    _this.setStorePickupPayment();
                }
                _this.checkUserMembership();
                _this.checkFreeProductStatus();
                _this.loader.dismiss();
            }
        });
        this.events.subscribe('delivery-settings:publishManageSlots', function (slots) {
            _this.manageSlots = slots;
        });
        this.events.subscribe('multi-region:publishActiveStatus', function (data) {
            if (data) {
                _this.multiRegion = data.active;
                _this.regionType = data.regionType || 'pincodes';
                if (_this.multiRegion && _this.regionType === 'area') {
                    _this.getNearestStoreRegion();
                }
            }
        });
    };
    DeliveryScheduleModalPage.prototype.isEmptyObj = function (object) {
        for (var key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }
        return true;
    };
    DeliveryScheduleModalPage.prototype.presentAutoConfirmPaymentPageModal = function (orderObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isCodAvailableForCoupon, order, modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
                        isCodAvailableForCoupon = orderObj.isCodAvailableForCoupon, order = tslib_1.__rest(orderObj, ["isCodAvailableForCoupon"]);
                        return [4 /*yield*/, this.modalController.create({
                                component: AutoConfirmPaymentPage,
                                cssClass: 'custom-modal',
                                componentProps: {
                                    orderData: order,
                                    isCodAvailableForCoupon: isCodAvailableForCoupon
                                }
                            })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.getDeliveryDates = function (days, lastDeliveryTime) {
        this.showLoader = false;
        this.scheduledDates = [];
        for (var index = 0; index < this.maxDaysOfDelivery; index++) {
            var dayToCheck = moment().add(index + 1, 'days');
            if (days.includes(dayToCheck.format('dddd'))) {
                this.scheduledDates.push(dayToCheck.toDate());
            }
        }
        if (this.allowSameDayDelivery) {
            this.appendSameDateToDeliveryDates(lastDeliveryTime);
        }
        this.minHrsReqCheck();
    };
    DeliveryScheduleModalPage.prototype.appendSameDateToDeliveryDates = function (lastTime) {
        var now = moment().format('HH:mm');
        var lastDeliveryTime = moment(lastTime, ['hh:mm A']).format('HH:mm');
        if (now < lastDeliveryTime) {
            this.scheduledDates.unshift(new Date());
        }
    };
    DeliveryScheduleModalPage.prototype.minHrsReqCheck = function () {
        var now = parseInt(moment().format('HH'));
        var t1 = now + this.minHrsReq;
        if (t1 > 24) {
            var disabledDays = Math.floor(t1 / 24);
            this.actualTime = t1 % 24;
            var actualDays = [];
            disabledDays = this.allowSameDayDelivery ? disabledDays : disabledDays - 1;
            disabledDays = disabledDays < 0 ? 0 : disabledDays;
            for (var index = disabledDays; index < this.scheduledDates.length; index++) {
                actualDays.push(this.scheduledDates[index]);
            }
            this.scheduledDates = actualDays;
            this.needToDisable = true;
        }
        else {
            this.actualTime = t1;
            if (this.allowSameDayDelivery) {
                this.needToDisable = true;
            }
        }
    };
    DeliveryScheduleModalPage.prototype.selectDate = function (e) {
        this.selectedDate = e.target.value;
        this.selectedTime = null;
    };
    DeliveryScheduleModalPage.prototype.selectTime = function (e) {
        this.selectedTime = e.target.value;
    };
    DeliveryScheduleModalPage.prototype.disableTime = function (time) {
        var _this = this;
        if (this.selectedDate) {
            if (this.needToDisable) {
                var d1 = this.formatDate(this.selectedDate);
                var d2 = this.formatDate(new Date(this.scheduledDates[0]));
                if (d1 === d2) {
                    var startTime = moment(time.start, ['hh:mm A']).format('HH');
                    if (this.actualTime > parseInt(startTime)) {
                        return true;
                    }
                }
            }
            if (this.manageSlots.length > 0) {
                var needToDisable_1 = false;
                this.manageSlots.forEach(function (manageSlot) {
                    if (_this.selectedDate.toDateString() === manageSlot.id) {
                        var slots = manageSlot.slots;
                        slots.forEach(function (slot) {
                            if ((time.start === slot.start) && (time.end === slot.end)) {
                                if (!slot.active) {
                                    needToDisable_1 = true;
                                }
                                if (slot.orderLimit <= slot.orderCreated) {
                                    needToDisable_1 = true;
                                }
                            }
                        });
                    }
                });
                if (needToDisable_1) {
                    return true;
                }
            }
        }
    };
    DeliveryScheduleModalPage.prototype.formatDate = function (d) {
        var day = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        return day + '/' + month + '/' + year;
    };
    DeliveryScheduleModalPage.prototype.onClickPlaceOrder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uploadDocMandatory, paymentData, isProductsUpdated;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.userId) {
                            this.sharedService.openLoginModal();
                            return [2 /*return*/];
                        }
                        if (!this.address) {
                            this.onClickChangeOrAddAddress('shipping');
                            return [2 /*return*/];
                        }
                        this.productsPrice = 0;
                        this.products.map(function (p) {
                            _this.productsPrice += ((('pack' in p) && (p.pack.variantType === 'pieces')) ? p.pack.price : p.price) * p.quantity;
                        });
                        uploadDocMandatory = false;
                        if (this.uploadDocSettings.isActive && this.uploadDocSettings.isMandatory && !this.uploadedDoc.uploads.length) {
                            uploadDocMandatory = true;
                        }
                        if (!(this.isOrderDeliverable === 'not_deliverable')) return [3 /*break*/, 1];
                        this.presentAlert(this.getNotDeliverableError());
                        return [3 /*break*/, 15];
                    case 1:
                        if (!(this.productsPrice < this.minOrderAmount)) return [3 /*break*/, 2];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order'] + " " + this.currencyCode + " " + this.minOrderAmount);
                        return [3 /*break*/, 15];
                    case 2:
                        if (!(this.maxOrderAmount && (this.productsPrice > this.maxOrderAmount))) return [3 /*break*/, 3];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['max_amount_for_placing_order'] + " " + this.currencyCode + " " + this.maxOrderAmount);
                        return [3 /*break*/, 15];
                    case 3:
                        if (!(this.deliveryType !== 'pickup' && this.isDeliveryScheduleMandatory && (!this.selectedDate || !this.selectedTime) && this.isNoSubscriptionOrder && !this.isOnlyMembership)) return [3 /*break*/, 4];
                        this.presentAlert("" + this.ORDER_SUMMARY_LABELS['select_delivery_date_time']);
                        return [3 /*break*/, 15];
                    case 4:
                        if (!(this.productsPrice < this.minPincodeAmnt)) return [3 /*break*/, 5];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order_on_pincode'] + " " + this.currencyCode + " " + this.minPincodeAmnt);
                        return [3 /*break*/, 15];
                    case 5:
                        if (!uploadDocMandatory) return [3 /*break*/, 6];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['please_upload_doc'] + " " + this.uploadDocSettings.name);
                        return [3 /*break*/, 15];
                    case 6:
                        if (!this.regionNotDeliverable) return [3 /*break*/, 7];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
                        return [3 /*break*/, 15];
                    case 7:
                        if (!this.issueInGettingLocation) return [3 /*break*/, 8];
                        this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location']);
                        return [3 /*break*/, 15];
                    case 8:
                        if ((this.totalAmountToPaid - this.defaultDeliveryAmt) >= this.freeDeliveryAmt) {
                            this.defaultDeliveryAmt = 0;
                        }
                        paymentData = {
                            productsPrice: this.productsPrice,
                            delivery: this.defaultDeliveryAmt,
                            couponDiscount: this.couponDiscount,
                            defaultGst: this.gstAmount,
                            totalAmountToPaid: this.totalAmountToPaid,
                            couponId: this.couponId,
                            couponName: this.couponCode,
                            scheduledDate: this.selectedDate ? this.selectedDate : '',
                            scheduledTime: this.selectedTime ? this.selectedTime : '',
                            totalMrp: this.totalMrp,
                            discountOnMrp: this.discountOnMrp,
                            deliveryGstObj: this.deliveryGstObj,
                            customerGstNo: this.customerGstNo,
                            billingAddress: this.billingAddress,
                            membershipDiscount: this.membershipDiscount,
                            storePickupObj: {},
                            uploadedDoc: this.uploadedDoc,
                            estimatedDeliveryTime: '',
                            instantDelivery: this.deliveryType === 'instant' ? { selected: true, time: this.instantDelivery.time } : { selected: false },
                            metaData: { source: 'browser', inventoryManaged: this.autoConfirmOrder ? true : false },
                            autoConfirmOrder: this.autoConfirmOrder,
                            freeProductsAdded: this.freeProductsAdded,
                            extraChargeOnOrder: this.extraChargeOnOrder,
                            custom: this.customField,
                            totalAddonsPrice: this.totalAddonsPrice
                        };
                        if (this.deliveryType === 'default_delivery' && this.storeDelivery.estimatedTime) {
                            paymentData.estimatedDeliveryTime = this.storeDelivery.estimatedTime;
                        }
                        if (this.deliveryType === 'estimated') {
                            paymentData.estimatedDeliveryTime = this.estimatedDeliveryTime;
                        }
                        if (this.deliveryType === 'pickup' && this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
                            paymentData['storePickupObj'] = {
                                storeAddress: this.storeAddress,
                                charges: this.storePickupCharges
                            };
                        }
                        if (!!this.autoConfirmOrder) return [3 /*break*/, 14];
                        return [4 /*yield*/, this.sharedService.presentLoading(20000)];
                    case 9:
                        _a.sent();
                        return [4 /*yield*/, this.cartService.compareCartWithUpdatedCart(this.products, 'order-summary')];
                    case 10:
                        isProductsUpdated = (_a.sent()).cartUpdated;
                        if (!isProductsUpdated) return [3 /*break*/, 12];
                        this.sharedService.loading.dismiss();
                        return [4 /*yield*/, this.presentAlert(this.SHARED_LABELS['cart_updated_alert'])];
                    case 11:
                        _a.sent();
                        this.router.navigate(['user-cart']);
                        return [3 /*break*/, 13];
                    case 12:
                        this.events.publish('order:placeOrder', this.products, this.listOfCommentImages, this.address, paymentData);
                        _a.label = 13;
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        this.events.publish('order:autoConfirmPlaceOrder', this.products, this.listOfCommentImages, this.address, paymentData, this.isCodAvailableForCoupon);
                        _a.label = 15;
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.onClickChangeOrAddAddress = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: SelectAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: { type: type }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return tslib_1.__generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = this;
                                        return [4 /*yield*/, this.storage.get('userDefaultAddress')];
                                    case 1:
                                        _a.address = _c.sent();
                                        _b = this;
                                        return [4 /*yield*/, this.storage.get('userBillingAddress')];
                                    case 2:
                                        _b.billingAddress = _c.sent();
                                        this.events.publish('delivery-settings:getDeliverySettingsData');
                                        this.events.publish('delivery-settings:getManageSlots');
                                        if (this.multiRegion && this.regionType === 'area') {
                                            this.getNearestStoreRegion();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.applyCouponCode = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.userId) {
                            this.sharedService.openLoginModal();
                            return [2 /*return*/];
                        }
                        this.paymentDetails.products = this.products.filter(function (p) { return p.orderType !== 'free'; });
                        return [4 /*yield*/, this.presentLoading("" + this.ORDER_SUMMARY_LABELS['verifying_coupon_code'])];
                    case 1:
                        _a.sent();
                        data = {
                            code: this.couponCode,
                            paymentDetails: this.paymentDetails,
                            isGstApplicable: this.isGstApplicable
                        };
                        this.events.publish('coupon-codes:verifyCouponCode', data);
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.removeCouponCode = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.couponApplied = false;
                this.couponDiscount = 0;
                this.couponCode = '';
                this.couponId = '';
                this.isCodAvailableForCoupon = true;
                this.initPaymentInfo();
                this.checkFreeProductStatus();
                return [2 /*return*/];
            });
        });
    };
    DeliveryScheduleModalPage.prototype.openCouponsModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.couponApplied) return [3 /*break*/, 1];
                        return [2 /*return*/];
                    case 1: return [4 /*yield*/, this.modalController.create({
                            component: CouponsListPage,
                            cssClass: 'coupons-list-modal modal-bg'
                        })];
                    case 2:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (res && res.data) {
                                    this.couponCode = res.data.couponName;
                                    this.applyCouponCode();
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, modal.present()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.scrollToBottom = function () {
        this.content.scrollToBottom(500);
    };
    DeliveryScheduleModalPage.prototype.orderPlacedAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: this.ORDER_SUMMARY_LABELS['order_placed_alert_header'],
                            message: this.ORDER_SUMMARY_LABELS['order_placed_alert_message'],
                            backdropDismiss: false,
                            buttons: [{
                                    text: 'OK',
                                    handler: function () {
                                        _this.navCtrl.navigateRoot(['order-successful']);
                                        _this.modalController.dismiss();
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: this.SHARED_LABELS['ok']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.presentLoading = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg,
                                duration: 30000
                            })];
                    case 1:
                        _a.loader = _b.sent();
                        return [4 /*yield*/, this.loader.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.textUppercase = function () {
        this.couponCode = this.couponCode.toUpperCase();
        if (this.couponCode.includes(' ')) {
            this.couponCode = this.couponCode.replace(/\s/g, '');
        }
    };
    DeliveryScheduleModalPage.prototype.changeAddressType = function (e) {
        this.addressType = e.target.value;
        if (this.addressType === 'billing') {
            if (this.isSame(this.address, this.billingAddress)) {
                this.isShippingAndBillingSame = true;
            }
            else {
                this.isShippingAndBillingSame = false;
            }
        }
    };
    DeliveryScheduleModalPage.prototype.isSame = function (a, b) {
        if (a.id === b.id) {
            return true;
        }
        else {
            return false;
        }
    };
    DeliveryScheduleModalPage.prototype.changePersonalType = function (e) {
        this.accountType = e.target.value;
        if (this.accountType === 'personal') {
            this.customerGstNo = '';
        }
    };
    DeliveryScheduleModalPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    DeliveryScheduleModalPage.prototype.changeDeliveryType = function (e) {
        this.couponApplied = false;
        this.couponDiscount = 0;
        this.deliveryType = e.target.value;
        if (this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
            if (this.deliveryType === 'pickup') {
                this.setStorePickupPayment();
                this.checkUserMembership();
            }
            else {
                this.selectedDate = null;
                this.selectedTime = null;
                this.initPaymentInfo();
            }
        }
    };
    DeliveryScheduleModalPage.prototype.checkUserMembership = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var membership, membershipInCart, settings, totalProductPrice, memberDiscount_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                membership = { isMember: false, settings: {} };
                membershipInCart = false;
                if (this.user && this.user.hasOwnProperty('membership') && this.user.membership.isMember) {
                    membership.isMember = true;
                    membership['settings'] = this.user.membership.planConfig;
                }
                else {
                    this.products.forEach(function (product) {
                        if (product.hasOwnProperty('orderType') && product.orderType === 'membership') {
                            membership.isMember = true;
                            membership['settings'] = product.membershipSettings;
                            membershipInCart = true;
                        }
                    });
                    if (membership.isMember && this.products.length === 1) {
                        this.isOnlyMembership = true;
                        this.freeDeliveryAmt = 0;
                        this.deliveryFree();
                    }
                }
                if (membership.isMember && !this.isOnlyMembership) {
                    settings = membership.settings;
                    console.log('settings', settings);
                    totalProductPrice = this.totalMrp - this.discountOnMrp - this.couponDiscount;
                    if (settings.isDeliveryFree && (totalProductPrice >= settings.deliveryFreeAmount)) {
                        this.deliveryFree();
                        this.freeDeliveryAmt = settings.deliveryFreeAmount;
                    }
                    if (settings.discount > 0) {
                        memberDiscount_1 = 0;
                        if (membershipInCart) {
                            memberDiscount_1 = (totalProductPrice - settings.plan.discountedPrice) * (settings.discount / 100);
                        }
                        else {
                            memberDiscount_1 = totalProductPrice * (settings.discount / 100);
                        }
                        memberDiscount_1 = parseFloat((memberDiscount_1 > settings.maxDiscount ? settings.maxDiscount : memberDiscount_1).toFixed(2));
                        this.products.map(function (pdt) {
                            var discountOnPdt = memberDiscount_1 * (pdt.price / (_this.totalMrp - _this.discountOnMrp));
                            pdt.membershipDiscount = (discountOnPdt > pdt.price) ? pdt.price * pdt.quantity : discountOnPdt * pdt.quantity;
                        });
                        this.membershipDiscount = memberDiscount_1;
                        this.totalAmountToPaid = (this.totalAmountToPaid - memberDiscount_1) < 0 ? 0 : this.totalAmountToPaid - memberDiscount_1;
                    }
                }
                console.log('this.membershipDiscount', this.membershipDiscount);
                return [2 /*return*/];
            });
        });
    };
    DeliveryScheduleModalPage.prototype.initPaymentInfo = function () {
        this.products = this.paymentDetails.products;
        this.defaultDeliveryAmt = this.paymentDetails.delivery.deliveryCost;
        this.isOrderDeliverable = this.paymentDetails.delivery.status;
        this.totalMrp = this.paymentDetails.totalMrp;
        this.discountOnMrp = this.paymentDetails.discountOnMrp;
        this.gstAmount = this.paymentDetails.totalGst;
        this.deliveryGstObj = this.paymentDetails.deliveryGstObj;
        this.totalAmountToPaid = this.paymentDetails.totalPayable;
        this.extraChargeOnOrder.charge = 'extraCharge' in this.paymentDetails ? this.paymentDetails.extraCharge : 0;
        if (this.deliveryType === 'pickup') {
            this.checkIfOnlyStorePickup();
        }
        this.checkUserMembership();
        if ('freeDeliveryAmount' in this.paymentDetails.delivery && this.paymentDetails.delivery.freeDeliveryAmount) {
            this.freeDeliveryAmt = this.paymentDetails.delivery.freeDeliveryAmount;
        }
    };
    DeliveryScheduleModalPage.prototype.deliveryFree = function () {
        this.defaultDeliveryAmt = 0;
        this.deliveryGstObj = {
            value: 0,
            total: 0
        };
        this.totalAmountToPaid -= this.paymentDetails.delivery.deliveryCost;
    };
    DeliveryScheduleModalPage.prototype.checkIfOnlyStorePickup = function () {
        if ((!this.isDeliverySchedule || !this.scheduledDates.length) && (this.isStorePickup && this.storeAddress.hasOwnProperty('address'))) {
            this.setStorePickupPayment();
        }
    };
    DeliveryScheduleModalPage.prototype.setStorePickupPayment = function () {
        this.totalAmountToPaid = parseFloat(((this.paymentDetails.totalPayable - this.paymentDetails.delivery.deliveryCost - this.couponDiscount) + this.storePickupCharges).toFixed(2));
        this.gstAmount += (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100)))) - this.paymentDetails.deliveryGstObj.total;
        this.deliveryGstObj.total = (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100))));
    };
    DeliveryScheduleModalPage.prototype.uploadDocImage = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, base64Image;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 5];
                        if (!(this.uploadedDoc.uploads.length < this.orderUploadedDocImgsLimit)) return [3 /*break*/, 3];
                        console.log('in if....');
                        return [4 /*yield*/, this.readBase64(files.item(i))];
                    case 2:
                        base64Image = _a.sent();
                        this.uploadedDoc.uploads.push({ url: base64Image });
                        return [3 /*break*/, 4];
                    case 3:
                        console.log('in else....');
                        return [3 /*break*/, 5];
                    case 4:
                        i++;
                        return [3 /*break*/, 1];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.readBase64 = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var reader;
            return tslib_1.__generator(this, function (_a) {
                reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (event) {
                    resolve(event.target.result);
                };
                return [2 /*return*/];
            });
        }); });
    };
    DeliveryScheduleModalPage.prototype.removeUploadedDocImg = function (index) {
        this.uploadedDoc.uploads.splice(index, 1);
    };
    DeliveryScheduleModalPage.prototype.imgZoom = function (img) {
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: [{ url: img }],
                index: 0
            }
        }).then(function (modal) { return modal.present(); });
    };
    DeliveryScheduleModalPage.prototype.isDeliverySchedulesAvailable = function () {
        return this.isDeliverySchedule && this.scheduledDates.length > 0 ? true : false;
    };
    DeliveryScheduleModalPage.prototype.isStorePickupAvailable = function () {
        return this.isStorePickup && this.storeAddress.hasOwnProperty('address') ? true : false;
    };
    DeliveryScheduleModalPage.prototype.isDefaultDeliveryAvailable = function () {
        return this.storeDelivery.isActive ? true : false;
    };
    DeliveryScheduleModalPage.prototype.getExtraDeliveryFields = function () {
        var data = this.paymentDetails.delivery;
        if ('minAmount' in data) {
            this.minPincodeAmnt = data.minAmount;
        }
        if ('estimatedDeliveryTime' in data) {
            console.log('estimatedDeliveryTime', data.estimatedDeliveryTime);
            if (data.estimatedDeliveryTime) {
                var totalEstimatedHrs = this.getEstimatedTimeInHrs(data.estimatedDeliveryTime);
                var estimatedDate = moment().add(totalEstimatedHrs, 'hours').format('dddd, MMMM DD');
                this.estimatedDeliveryTime = estimatedDate;
                if (this.instantDelivery.isActive) {
                    this.checkInstantDelivery(totalEstimatedHrs);
                }
            }
        }
        if ('freeDeliveryAmount' in data) {
            this.freeDeliveryAmt = data.freeDeliveryAmount;
        }
    };
    DeliveryScheduleModalPage.prototype.getEstimatedTimeInHrs = function (time) {
        var splitTime = time.split(':');
        var days = +splitTime[0];
        var hrs = +splitTime[1];
        var mins = +splitTime[2];
        var totalHrs = (days * 24) + hrs + (mins / 60);
        return totalHrs;
    };
    DeliveryScheduleModalPage.prototype.showDeliverySection = function () {
        if ((this.isDeliverySchedulesAvailable() || this.isStorePickupAvailable() || this.isInstantDeliveryAvailable() || this.isEstimatedDeliveryAvailable() || this.isDefaultDeliveryAvailable())
            && this.isNoSubscriptionOrder && !this.isOnlyMembership) {
            return true;
        }
        else {
            return false;
        }
    };
    DeliveryScheduleModalPage.prototype.isInstantDeliveryAvailable = function () {
        return this.instantDelivery.isAvailable;
    };
    DeliveryScheduleModalPage.prototype.isEstimatedDeliveryAvailable = function () {
        return this.estimatedDeliveryTime && this.estimatedDeliveryTime !== '' ? true : false;
    };
    DeliveryScheduleModalPage.prototype.setDefaultSelectionofDelivery = function () {
        if (this.isDeliverySchedulesAvailable()) {
            this.deliveryType = 'delivery';
        }
        else if (this.isInstantDeliveryAvailable()) {
            this.deliveryType = 'instant';
        }
        else if (!this.isDeliverySchedulesAvailable() && this.isEstimatedDeliveryAvailable()) {
            console.log('isEstimatedDeliveryAvailable');
            this.deliveryType = 'estimated';
        }
        else if (!this.isDeliverySchedulesAvailable() && !this.isEstimatedDeliveryAvailable() && this.isDefaultDeliveryAvailable()) {
            this.deliveryType = 'default_delivery';
        }
        else if (this.isStorePickupAvailable()) {
            console.log('isStorePickupAvailable');
            this.deliveryType = 'pickup';
        }
    };
    DeliveryScheduleModalPage.prototype.checkInstantDelivery = function (estimatedTime) {
        var todayAvailable = moment().isSame(moment(this.scheduledDates[0]));
        if ((this.instantDelivery.time >= estimatedTime) && todayAvailable) {
            this.instantDelivery['isAvailable'] = true;
            this.instantDelivery.time = this.instantDelivery.time <= 1 ? this.instantDelivery.time * 60 + " mins" : this.instantDelivery.time + " hrs";
        }
    };
    DeliveryScheduleModalPage.prototype.isMembershipInProducts = function (orderType) {
        return orderType && orderType === 'membership' ? true : false;
    };
    DeliveryScheduleModalPage.prototype.uploadCommentImgs = function (files, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imgs, i, base64Image;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        imgs = this.listOfCommentImages[pid] || [];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.readBase64(files.item(i))];
                    case 2:
                        base64Image = _a.sent();
                        if (imgs.length !== 0) {
                            imgs.push(base64Image);
                        }
                        else {
                            imgs = [base64Image];
                        }
                        this.listOfCommentImages[pid] = imgs;
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.removeCommentImage = function (imgIndex, pid) {
        this.listOfCommentImages[pid].splice(imgIndex, 1);
        console.log('listOfCommentImages', this.listOfCommentImages);
    };
    DeliveryScheduleModalPage.prototype.getNearestStoreRegion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, region;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentLoading(20000, this.ORDER_SUMMARY_LABELS['getting_nearest_store_location'])];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getRegion()];
                    case 2:
                        res = _a.sent();
                        if (res.status === 'success') {
                            if (res.deliverable) {
                                region = {
                                    id: res.region.id,
                                    name: res.region.name,
                                    pincode: null,
                                    vendorId: res.region.vendorId || null
                                };
                                this.storage.set('region', region);
                                this.sharedService.loading.dismiss();
                            }
                            else {
                                this.sharedService.loading.dismiss();
                                this.storage.set('region', null);
                                this.regionNotDeliverable = true;
                                this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
                            }
                        }
                        else {
                            this.sharedService.loading.dismiss();
                            this.issueInGettingLocation = true;
                            this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.getRegion = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var nearestRegion;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.regionService.getNearestStoreRegion(this.address)];
                    case 1:
                        nearestRegion = _a.sent();
                        resolve(nearestRegion);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DeliveryScheduleModalPage.prototype.getFreeProductLimits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var limits;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cartService.getFreeProductLimits()];
                    case 1:
                        limits = _a.sent();
                        if (limits.length) {
                            this.freeProductsLimit = limits;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryScheduleModalPage.prototype.checkFreeProductStatus = function () {
        this.freeProductsAdded = [];
        var products = this.products.filter(function (p) { return p.orderType !== 'free'; });
        if (this.freeProductsLimit.length) {
            var totalPrice = this.getTotalPriceWithoutDelivery();
            var limitIndex = -1;
            for (var index = 0; index < this.freeProductsLimit.length; index++) {
                if (totalPrice >= this.freeProductsLimit[index].orderAmount) {
                    limitIndex = index;
                    break;
                }
            }
            if (limitIndex !== -1) {
                this.freeProductsAdded.push(this.freeProductsLimit[limitIndex].id);
                this.freeProductsLimit[limitIndex].freeProduct['gstObj'] = {
                    value: 0,
                    total: 0,
                    cgst: 0,
                    sgst: 0,
                    igst: 0
                };
                this.freeProductsLimit[limitIndex].freeProduct['orderType'] = 'free';
                products.push(this.freeProductsLimit[limitIndex].freeProduct);
                this.products = JSON.parse(JSON.stringify(products));
            }
        }
    };
    DeliveryScheduleModalPage.prototype.moreAmountForFreeProducts = function () {
        return this.freeProductsLimit[this.freeProductsLimit.length - 1].orderAmount - this.getTotalPriceWithoutDelivery();
    };
    DeliveryScheduleModalPage.prototype.getTotalPriceWithoutDelivery = function () {
        return this.totalMrp - this.discountOnMrp - this.couponDiscount - this.membershipDiscount;
    };
    DeliveryScheduleModalPage.prototype.setDeliveryObj = function (data) {
        if (!this.isEmptyObj(data)) {
            if (data.freeDeliveryAmt !== '') {
                this.freeDeliveryAmt = parseInt(data.freeDeliveryAmt);
                console.log('freeDeliveryAmt', this.freeDeliveryAmt);
            }
            this.storeDelivery = 'isStoreDelivery' in data ? data.isStoreDelivery : this.storeDelivery;
            if (this.storeDelivery.isActive && this.storeDelivery.estimatedTime) {
                var hrs = this.getEstimatedTimeInHrs(this.storeDelivery.estimatedTime);
                this.storeDelivery.estimatedTime = moment().add(hrs, 'hours').format('dddd, MMMM DD');
            }
            this.isDeliverySchedule = data.isDeliverySchedule && this.storeDelivery.isActive ? true : false;
            this.isStorePickup = data.isStorePickup;
            if (data.storePickupCharges) {
                this.storePickupCharges = parseInt(data.storePickupCharges);
            }
            this.maxDaysOfDelivery = data.maxDaysOfDelivery;
            if (this.isDeliverySchedule) {
                if (data.allowSameDayDelivery) {
                    this.allowSameDayDelivery = data.allowSameDayDelivery;
                }
                if (data.minHrs) {
                    this.minHrsReq = data.minHrs;
                }
                if (data.deliveryDays.length > 0 && data.timeSchedules.length > 0) {
                    this.getDeliveryDates(data.deliveryDays, data.lastDeliveryTime);
                    this.timeSchedules = data.timeSchedules;
                }
            }
            this.isKmDeliveryExist = data.isKmBasedDelivery && this.storeAddress.lat && this.address.lat;
            this.isWeightDeliveryExist = data.hasOwnProperty('deliveryByWeight') && data.deliveryByWeight.active ? true : false;
            this.instantDelivery = 'instantDelivery' in data ? tslib_1.__assign({}, this.instantDelivery, data.instantDelivery) : this.instantDelivery;
            if (typeof data.isDeliveryScheduleMandatory !== 'undefined') {
                this.isDeliveryScheduleMandatory = data.isDeliveryScheduleMandatory;
            }
        }
    };
    DeliveryScheduleModalPage.prototype.changeCustomDeliveryOption = function (event) {
        var customDeliverySettings = this.customDeliveryOptions.find(function (c) { return c.id === event.target.value; });
        console.log('customDeliverySettings', customDeliverySettings);
        if (customDeliverySettings && Object.keys(customDeliverySettings).length) {
            this.setDeliveryObj(customDeliverySettings);
            this.events.publish('delivery-settings:getOrderPaymentDetails', this.address, this.products, this.isGstApplicable, customDeliverySettings);
            this.couponApplied = false;
            this.couponDiscount = 0;
            this.couponCode = '';
            this.couponId = '';
            this.showPriceInfoLoader = true;
            this.selectedCustomDeliveryType = customDeliverySettings.id;
        }
    };
    DeliveryScheduleModalPage.prototype.getNotDeliverableError = function () {
        if (this.selectedCustomDeliveryType) {
            return this.selectedCustomDeliveryType + " delivery type not available. Please select another option.";
        }
        else {
            return "" + this.ORDER_SUMMARY_LABELS['order_not_deliverable'];
        }
    };
    DeliveryScheduleModalPage.prototype.isCustomDeliveryAvailable = function (data) {
        var _this = this;
        if ('availableLocations' in data && this.address) {
            if (data.availableLocations.states.some(function (s) { return s.stateCode === _this.address.stateCode; })) {
                return true;
            }
            if (data.availableLocations.pincodes.some(function (p) { return p === _this.address.pincode; })) {
                return true;
            }
            return false;
        }
        else {
            return true;
        }
    };
    DeliveryScheduleModalPage.prototype.checkIsInternationalUser = function () {
        var adminCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry;
        if (this.address && adminCountry.countryName && adminCountry.countryName.toLowerCase() === 'india' && this.address.country && this.address.country.toLowerCase() !== 'india') {
            return true;
        }
        else {
            return false;
        }
    };
    DeliveryScheduleModalPage.prototype.isAddonAvailable = function (product) {
        return product.addOns && product.addOns.options && Object.keys(product.addOns.options).length;
    };
    DeliveryScheduleModalPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('order:orderSuccessfullyPlaced');
        this.events.unsubscribe('delivery-settings:publishDeliverySettingsData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('delivery-settings:publishOrderPaymentDetails');
        this.events.unsubscribe('coupon-codes:couponCodeNotApplied');
        this.events.unsubscribe('coupon-codes:couponCodeApplied');
        this.events.unsubscribe('delivery-settings:publishManageSlots');
        this.events.unsubscribe('order:autoConfirmPlaceOrderSuccess');
    };
    DeliveryScheduleModalPage.prototype.dateSelect = function (date) {
        this.selectedDate = date;
        this.selectedTime = null;
        console.log('selectedDate', this.selectedDate);
    };
    DeliveryScheduleModalPage.prototype.timeSelect = function (time) {
        this.selectedTime = time;
        console.log('selectedDate', this.selectedTime);
    };
    DeliveryScheduleModalPage.prototype.done = function () {
        this.modalController.dismiss({ selectedDate: this.selectedDate, selectedTime: this.selectedTime });
    };
    return DeliveryScheduleModalPage;
}());
export { DeliveryScheduleModalPage };
